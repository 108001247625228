import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import logout from "../assets/images/logout.png";
import logo from "../assets/images/logo.png";
import CloseIcon from "../assets/images/close_icon.png";

import { Logout } from "../store/reducers/auth";
import { dispatch, useSelector } from "../store";

function BellNotification({ open }) {
  const navigate = useNavigate();
  const { isLoggedIn, username } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(Logout())
    open(false);
    navigate("/login");
  };

  return (
    <div>
      <Box
        sx={{
          padding: "10px",
          borderRadius: { sm: "30px" },
          backgroundColor: { xs: "#131313", sm: "#232323" },
          width: { xs: "370px", sm: "420px" },
          right: { xs: 15, md: 19, xl: 205 },
          position: "absolute",
          top: { xs: 10, sm: 20 },
          height: { xs: "100%", md: "auto" },
          zIndex: 100,
        }}
        className="bell_notification"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "space-between", md: "end" },
            marginBottom: "13px",
          }}
        >
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <img
              alt="logo"
              src={logo}
              style={{ zIndex: 120, position: "relative" }}
            ></img>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={CloseIcon}
              onClick={() => open(false)}
              alt="notificaion"
              style={{ marginLeft: "10px", cursor: "pointer" }}
            ></img>
          </Box>
        </Box>
        <Box
          sx={{ minWidth: { xs: "370px", sm: "400px" } }}
          className="bell_inner_section"
        >
          {/* Name section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "18px 20px",
              borderRadius: "16px",
              backgroundColor: "#1b1b1b",
            }}
          >
            {isLoggedIn ? (
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: 500,
                  fontSize: 30,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                {username}
              </Typography>
            ) : (
              <Button
                sx={{
                  fontWeight: 600,
                  fontSize: 18,
                  color: "#fff",
                  backgroundColor: "#E63B1F",
                  borderRadius: "16px",
                  padding: "13px 0px",
                  textTransform: "none",
                  fontFamily: '"Inter", sans-serif',
                  "&::first-letter": {
                    textTransform: "capitalize",
                  },
                  "&:hover": {
                    background: "#E63B1F",
                  },
                }}
                onClick={() => {
                  open(false);
                  navigate('/login');
                }}
              >
                Login
              </Button>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <img
                src={setting}
                style={{ cursor: "pointer" }}
                alt="setting"
                onClick={() => {
                  setSettingOptions("settings")
                  navigate("/home/settings");
                  open(false);
                }}
              ></img> */}
              {isLoggedIn && (
                <img
                  src={logout}
                  style={{ marginLeft: "35px", cursor: "pointer" }}
                  alt="logout"
                  onClick={handleLogout}
                ></img>
              )}
            </Box>
          </Box>

        </Box>
      </Box>
    </div>
  );
}

export default BellNotification;
