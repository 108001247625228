import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";

import logo from "../assets/images/logo.png";

import { useSelector } from "../store";
import { usePovegoContext } from "../context";

function HeaderLayout({ toggleDrawer }) {
  const navigate = useNavigate();
  const { setShowCurreny, } = usePovegoContext();
  const { isLoggedIn, username, } = useSelector((state) => state.auth);

  const toggle = () => {
    if (username) {
      toggleDrawer();
      setShowCurreny(false);
    } else {
      navigate("/login");
    }
  };

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: "flex",
          padding: "23px 0px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/">
          <img alt="logo" src={logo} style={{ zIndex: 120, position: "relative" }}></img>
        </Link>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to={'/kyc'} style={{ textDecoration: 'none' }}>
            <Typography sx={{ display: { xs: 'none', md: 'block' }, color: '#fff', fontSize: 20, lineHeight: '24px', fontFamily: '"Inter", sans-serif', marginRight: '20px' }}>
              KYC
            </Typography>
          </Link>

          <Link to={'/withdrawal'} style={{ textDecoration: 'none' }}>
            <Typography sx={{ display: { xs: 'none', md: 'block' }, color: '#fff', fontSize: 20, lineHeight: '24px', fontFamily: '"Inter", sans-serif', marginRight: '20px' }}>
              Withdraw
            </Typography>
          </Link>
          <Link to={'/deposit'} style={{ textDecoration: 'none' }}>
            <Typography sx={{ display: { xs: 'none', md: 'block' }, color: '#fff', fontSize: 20, lineHeight: '24px', fontFamily: '"Inter", sans-serif', marginRight: '20px' }}>
              Deposit
            </Typography>
          </Link>

          <Link to={'/bank-withdrawal'} style={{ textDecoration: 'none' }}>
            <Typography sx={{ display: { xs: 'none', md: 'block' }, color: '#fff', fontSize: 20, lineHeight: '24px', fontFamily: '"Inter", sans-serif', marginRight: '20px' }}>
              Bank Withdraw
            </Typography>
          </Link>
          <Link to={'/bank-deposit'} style={{ textDecoration: 'none' }}>
            <Typography sx={{ display: { xs: 'none', md: 'block' }, color: '#fff', fontSize: 20, lineHeight: '24px', fontFamily: '"Inter", sans-serif', marginRight: '20px' }}>
              Bank Deposit
            </Typography>
          </Link>

          <Button
            sx={{
              fontWeight: 500,
              fontSize: 14,
              color: "#fff",
              backgroundColor: "#E63B1F",
              borderRadius: "20px",
              padding: isLoggedIn ? "5px 18px" : "5px 39px",
              textTransform: "none",
              fontFamily: '"Inter", sans-serif',
              "&::first-letter": {
                textTransform: "capitalize",
              },
              "&:hover": {
                background: "#E63B1F",
              },
            }}
            onClick={() => toggle()}
          >
            {isLoggedIn ? username : "Giriş Yap"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default HeaderLayout;
