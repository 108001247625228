import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Login from './pages/Login';
import { useSelector } from './store';
import Layout from './layout';
import DepositPage from './pages/DepositPage';
import WithdrawalPage from './pages/WithdrawalPage';
import BankDepositPage from './pages/BankDepositPage';
import BankWithdrawalPage from './pages/BankWithdrawalPage';
import KYCPage from './pages/KYC';

function Router() {
  const { isLoggedIn } = useSelector((state) => state.auth);

  return (
    <div>
      <Routes>
        <Route exact path='/' element={!isLoggedIn ? <Navigate to={'/login'} /> : <Layout><DepositPage /></Layout>} />
        <Route path='/login' element={isLoggedIn ? <Navigate to={'/deposit'} /> : <Layout><Login /></Layout>} />
        <Route path='/withdrawal' element={isLoggedIn ? <Layout><WithdrawalPage /></Layout> : <Navigate to={'/login'} />} />
        <Route path='/deposit' element={isLoggedIn ? <Layout><DepositPage /></Layout> : <Navigate to={'/login'} />} />
        <Route path='/bank-deposit' element={isLoggedIn ? <Layout><BankDepositPage /></Layout> : <Navigate to={'/login'} />} />
        <Route path='/bank-withdrawal' element={isLoggedIn ? <Layout><BankWithdrawalPage /></Layout> : <Navigate to={'/login'} />} />
        <Route path='/kyc' element={isLoggedIn ? <Layout><KYCPage /></Layout> : <Navigate to={'/login'} />} />
      </Routes>
    </div>
  );
}

export default Router;
