import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CryptoJS from "crypto-js";
import { confirmDeposit, confirmKYC, getAllKYC, rejectKYC } from "../apis";
import toast from "react-hot-toast";
import { formatDateString } from "../helpers";

function KYCPage() {
  const [kycs, setKYCs] = useState([])

  const reject = async (userId) => {
    try {
      const hashed = CryptoJS.HmacSHA256(
        JSON.stringify(userId),
        process.env.REACT_APP_TOKEN
      ).toString(CryptoJS.enc.Hex);
      const data = await rejectKYC(userId, hashed)
      if (data) {
        fetchData()
        toast.success("KYC rejected successfully.")
      }
    } catch (error) {
      toast.error("There is an error.")

    }
  }

  const confirm = async (userId) => {
    try {
      const hashed = CryptoJS.HmacSHA256(
        JSON.stringify(userId),
        process.env.REACT_APP_TOKEN
      ).toString(CryptoJS.enc.Hex);
      const data = await confirmKYC(userId, hashed)
      if (data) {
        fetchData()
        toast.success("KYC approved successfully.")
      }
    } catch (error) {
      toast.error("There is an error.")
    }
  }

  const fetchData = async () => {
    const data = await getAllKYC()
    console.log(data, "data")
    setKYCs(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            paddingTop: "60px",
          }}
        >

          <TableContainer component={Paper} sx={{ backgroundColor: "#131313", }}>
            <Table
              sx={{ border: "none", backgroundColor: "#131313" }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#131313" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                  >
                    UserId
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    First Name
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    Last Name
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    Phone
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    Id Photo
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    Selfie Photo
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    Actions
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: "#1B1B1B", border: "none" }}>
                {kycs?.map((item, key) => (
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontFamily: '"SF Mono", monospace',
                        color: "#fff",
                        borderTopLeftRadius: "16px",
                        borderBottomLeftRadius: "16px",
                        padding: "13px",
                      }}
                    >
                      {item.userId}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                      }}
                      align="left"
                    >
                      {item.firstName}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                      }}
                      align="left"
                    >
                      {item.lastName}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                      }}
                      align="left"
                    >
                      {item.phoneNumber}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                      }}
                      align="left"
                    >

                      <img style={{ height: "100px" }} src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${item.idPhotoName}`}>
                      </img>
                    </TableCell>

                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                      }}
                      align="left"
                    >
                      <img style={{ height: "100px" }} src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${item.selfiePhotoName}`}>
                      </img>
                    </TableCell>

                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                      }}
                      align="left"
                    >
                      {item.kyc_status}
                    </TableCell>

                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                        borderTopRightRadius: "16px",
                        borderBottomRightRadius: "16px",
                      }}
                      align="left"
                    >

                      <Button
                        sx={{
                          fontWeight: 600,
                          fontSize: 15,
                          color: "#fff",
                          backgroundColor: "#E63B1F",
                          borderRadius: "16px",
                          padding: "10px 27px",
                          textTransform: "none",
                          fontFamily: '"Inter", sans-serif',
                          "&::first-letter": {
                            textTransform: "capitalize",
                          },
                          "&:hover": {
                            background: "#E63B1F",
                          },
                        }}
                        onClick={() => reject(item.userId)}
                      >
                        Reject
                      </Button>
                      <Button
                        sx={{
                          fontWeight: 600,
                          fontSize: 15,
                          color: "#fff",
                          backgroundColor: "#E63B1F",
                          borderRadius: "16px",
                          padding: "10px 21px",
                          textTransform: "none",
                          marginLeft: "8px",
                          marginRight: "10px",
                          fontFamily: '"Inter", sans-serif',
                          "&::first-letter": {
                            textTransform: "capitalize",
                          },
                          "&:hover": {
                            background: "#E63B1F",
                          },
                        }}
                        onClick={() => confirm(item.userId)}
                      >
                        Approve
                      </Button>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default KYCPage;
