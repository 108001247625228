import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { confirmBankWithdraw, getBankAllWithdraws } from "../apis";
import { formatDateString } from "../helpers";
import CryptoJS from "crypto-js";
import toast from "react-hot-toast";

function BankWithdrawalPage() {
  const [withdraws, setwithdraws] = useState([])

  const reject = async (withdrawId) => {

  }


  const confirm = async (withdrawId) => {
    try {
      const hashed = CryptoJS.HmacSHA256(
        JSON.stringify(withdrawId),
        process.env.REACT_APP_TOKEN
      ).toString(CryptoJS.enc.Hex);
      const data = await confirmBankWithdraw(withdrawId, hashed)
      if (data) {
        fetchData()
        toast.success(data.message)
      }
    } catch (error) {

    }
  }

  const fetchData = async () => {
    const data = await getBankAllWithdraws()
    setwithdraws(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            paddingTop: "60px",
          }}
        >

          <TableContainer component={Paper} sx={{ backgroundColor: "#131313" }}>
            <Table
              sx={{ minWidth: 650, border: "none", backgroundColor: "#131313" }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#131313" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                  >
                    id
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    reference id
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    amount
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    user
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    date
                  </TableCell>

                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    bank
                  </TableCell>

                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    status
                  </TableCell>

                  <TableCell
                    sx={{
                      border: "none",
                      fontFamily: '"SF Mono", monospace',
                      color: "#fff",
                    }}
                    align="left"
                  >
                    actions
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: "#1B1B1B", border: "none" }}>
                {withdraws.map((item, key) => (
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontFamily: '"SF Mono", monospace',
                        color: "#fff",
                        borderTopLeftRadius: "16px",
                        borderBottomLeftRadius: "16px",
                        padding: "13px",
                      }}
                    >
                      {item.withdraw_id}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                      }}
                      align="left"
                    >
                      {item.reference_id}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                      }}
                      align="left"
                    >
                      {item.amount}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                      }}
                      align="left"
                    >
                      {item.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                      }}
                      align="left"
                    >
                      {formatDateString(item.created_at)}
                    </TableCell>

                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                      }}
                      align="left"
                    >
                      {item.to_bank}
                    </TableCell>

                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                      }}
                      align="left"
                    >
                      {item.status}
                    </TableCell>

                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        padding: "13px",
                        color: "#fff",
                        borderTopRightRadius: "16px",
                        borderBottomRightRadius: "16px",
                      }}
                      align="left"
                    >

                      <Button
                        sx={{
                          fontWeight: 600,
                          fontSize: 15,
                          color: "#fff",
                          backgroundColor: "#E63B1F",
                          borderRadius: "16px",
                          padding: "10px 27px",
                          textTransform: "none",
                          fontFamily: '"Inter", sans-serif',
                          "&::first-letter": {
                            textTransform: "capitalize",
                          },
                          "&:hover": {
                            background: "#E63B1F",
                          },
                        }}
                        onClick={() => reject(item.withdraw_id)}
                      >
                        Reject
                      </Button>
                      <Button
                        sx={{
                          fontWeight: 600,
                          fontSize: 15,
                          color: "#fff",
                          backgroundColor: "#E63B1F",
                          borderRadius: "16px",
                          padding: "10px 21px",
                          textTransform: "none",
                          marginLeft: "8px",
                          marginRight: "10px",
                          fontFamily: '"Inter", sans-serif',
                          "&::first-letter": {
                            textTransform: "capitalize",
                          },
                          "&:hover": {
                            background: "#E63B1F",
                          },
                        }}
                        onClick={() => confirm(item.withdraw_id)}
                      >
                        Confirm
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default BankWithdrawalPage;
