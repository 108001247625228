import {
  Box,
  Button,
  Container,
  Grid,
  Input,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CryptoJS from "crypto-js";
import { confirmBankDeposit, getBankAllDeposits, increaseBankDeposit } from "../apis";
import toast from "react-hot-toast";
import { formatDateString } from "../helpers";
import TryLogo from "../assets/images/try_logo.png"

function BankDepositPage() {
  const [deposits, setDeposits] = useState([])
  const [userReference, setUserReference] = useState()
  const [amount, setAmount] = useState()


  const reject = async (depositId) => {

  }

  const confirm = async (depositId) => {
    try {
      const hashed = CryptoJS.HmacSHA256(
        JSON.stringify(depositId),
        process.env.REACT_APP_TOKEN
      ).toString(CryptoJS.enc.Hex);
      const data = await confirmBankDeposit(depositId, hashed)
      if (data) {
        fetchData()
        toast.success(data.message)
      }
    } catch (error) {

    }
  }

  const update = async () => {
    try {
      const params = {
        referenceId: userReference,
        bank_id: 1,
        amount
      }
      const hashed = CryptoJS.HmacSHA256(
        JSON.stringify(params),
        process.env.REACT_APP_TOKEN
      ).toString(CryptoJS.enc.Hex);

      const data = await increaseBankDeposit(hashed, params)
      if (data) {
        fetchData()
        toast.success(data.message)
      }
    } catch (error) {

    }
  }

  const fetchData = async () => {
    const data = await getBankAllDeposits()
    console.log(data, "data")
    setDeposits(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Grid container spacing={5} sx={{ paddingTop: "60px" }}>
          <Grid item md={3}>
            <Stack spacing={2}>
              <Typography sx={{ color: "white" }}>Add balance</Typography>
              <Box
                sx={{
                  backgroundColor: "#1b1b1b",
                  position: "relative",
                  borderRadius: "16px",
                  marginTop: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    minHeight: { xl: "80px", sm: "80px", xs: "80px" },
                    padding: "2px 20px",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "#7d7d7d",
                        fontFamily: '"Roboto", sans-serif;',
                      }}
                    >
                      User Reference
                    </Typography>
                    <Input
                      className="swap-input"
                      value={userReference}
                      onChange={(e) => setUserReference(e.target.value)}
                      mt={2}
                      sx={{
                        fontSize: 33,
                        color: "#fff",
                        fontWeight: 500,
                        fontFamily: '"SF Mono", monospace',
                        border: "none",
                        height: "18px",
                        maxWidth: '200px'
                      }}
                    />
                  </Stack>
                  <Box
                    sx={{
                      paddingTop: "15px",
                      color: "#fff",
                      fontSize: 20,
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <img
                    src={CHGRLogo}
                    alt="usd"
                    style={{ paddingRight: "10px" }}
                  ></img> */}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#1b1b1b",
                  position: "relative",
                  borderRadius: "16px",
                  marginTop: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    minHeight: { xl: "80px", sm: "80px", xs: "80px" },
                    padding: "2px 20px",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "#7d7d7d",
                        fontFamily: '"Roboto", sans-serif;',
                      }}
                    >
                      Balance
                    </Typography>
                    <Input
                      className="swap-input"
                      value={amount}
                      onChange={(e) => {
                        if (/^\d+$/.test(Number(e.target.value)) || /^\d+\.\d+$/.test(e.target.value) || Number(e.target.value) === 0) {
                          setAmount(e.target.value.indexOf(".") === -1 ? Number(e.target.value) : e.target.value)
                        }
                      }}
                      mt={2}
                      sx={{
                        fontSize: 33,
                        color: "#fff",
                        fontWeight: 500,
                        fontFamily: '"SF Mono", monospace',
                        border: "none",
                        height: "18px",
                        maxWidth: '200px'
                      }}
                    />
                  </Stack>
                  <Box
                    sx={{
                      paddingTop: "15px",
                      color: "#fff",
                      fontSize: 20,
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={TryLogo}
                      alt="usd"
                      style={{ paddingRight: "10px" }}
                    ></img>
                    TRY
                  </Box>
                </Box>
              </Box>
              <Button
                sx={{
                  fontWeight: 600,
                  fontSize: 18,
                  color: "#fff",
                  backgroundColor: "#E63B1F",
                  borderRadius: "16px",
                  padding: "13px 0px",
                  marginTop: "10px",
                  textTransform: "none",
                  fontFamily: '"Inter", sans-serif',
                  "&::first-letter": {
                    textTransform: "capitalize",
                  },
                  "&:hover": {
                    background: "#E63B1F",
                  },
                }}
                fullWidth
                onClick={update}
              >
                Confirm
              </Button>

            </Stack>
          </Grid>
          <Grid item md={9}>
            <TableContainer component={Paper} sx={{ backgroundColor: "#131313", }}>
              <Table
                sx={{ border: "none", backgroundColor: "#131313" }}
                aria-label="simple table"
              >
                <TableHead sx={{ backgroundColor: "#131313" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        color: "#fff",
                      }}
                    >
                      id
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        color: "#fff",
                      }}
                      align="left"
                    >
                      balance
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        color: "#fff",
                      }}
                      align="left"
                    >
                      user
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        color: "#fff",
                      }}
                      align="left"
                    >
                      date
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        color: "#fff",
                      }}
                      align="left"
                    >
                      reference id
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        color: "#fff",
                      }}
                      align="left"
                    >
                      status
                    </TableCell>

                    <TableCell
                      sx={{
                        border: "none",
                        fontFamily: '"SF Mono", monospace',
                        color: "#fff",
                      }}
                      align="left"
                    >
                      actions
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: "#1B1B1B", border: "none" }}>
                  {deposits?.map((item, key) => (
                    <TableRow
                      key={key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          fontFamily: '"SF Mono", monospace',
                          color: "#fff",
                          borderTopLeftRadius: "16px",
                          borderBottomLeftRadius: "16px",
                          padding: "13px",
                        }}
                      >
                        {item.deposit_id}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          fontFamily: '"SF Mono", monospace',
                          padding: "13px",
                          color: "#fff",
                        }}
                        align="left"
                      >
                        {item.balance}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          fontFamily: '"SF Mono", monospace',
                          padding: "13px",
                          color: "#fff",
                        }}
                        align="left"
                      >
                        {item.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          fontFamily: '"SF Mono", monospace',
                          padding: "13px",
                          color: "#fff",
                        }}
                        align="left"
                      >
                        {formatDateString(item.created_at)}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          fontFamily: '"SF Mono", monospace',
                          padding: "13px",
                          color: "#fff",
                        }}
                        align="left"
                      >
                        {item.reference_id}
                      </TableCell>

                      <TableCell
                        sx={{
                          border: "none",
                          fontFamily: '"SF Mono", monospace',
                          padding: "13px",
                          color: "#fff",
                        }}
                        align="left"
                      >
                        {item.status}
                      </TableCell>

                      <TableCell
                        sx={{
                          border: "none",
                          fontFamily: '"SF Mono", monospace',
                          padding: "13px",
                          color: "#fff",
                          borderTopRightRadius: "16px",
                          borderBottomRightRadius: "16px",
                        }}
                        align="left"
                      >

                        <Button
                          sx={{
                            fontWeight: 600,
                            fontSize: 15,
                            color: "#fff",
                            backgroundColor: "#E63B1F",
                            borderRadius: "16px",
                            padding: "10px 27px",
                            textTransform: "none",
                            fontFamily: '"Inter", sans-serif',
                            "&::first-letter": {
                              textTransform: "capitalize",
                            },
                            "&:hover": {
                              background: "#E63B1F",
                            },
                          }}
                          onClick={() => reject(item.deposit_id)}
                        >
                          Reject
                        </Button>
                        <Button
                          sx={{
                            fontWeight: 600,
                            fontSize: 15,
                            color: "#fff",
                            backgroundColor: "#E63B1F",
                            borderRadius: "16px",
                            padding: "10px 21px",
                            textTransform: "none",
                            marginLeft: "8px",
                            marginRight: "10px",
                            fontFamily: '"Inter", sans-serif',
                            "&::first-letter": {
                              textTransform: "capitalize",
                            },
                            "&:hover": {
                              background: "#E63B1F",
                            },
                          }}
                          onClick={() => confirm(item.deposit_id)}
                        >
                          Confirm
                        </Button>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

        </Grid>

      </Container>
    </React.Fragment >
  );
}

export default BankDepositPage;
