import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: "",
    userId: 0,
    username: "",
    isVerified: false,
    isLoggedIn: false,
    wallet: "",
    mail: "",
    referenceId: ""
};

const auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        Login(state, action) {
            const data = action.payload;
            state.token = data.access_token;
            state.userId = data.id;
            state.username = data.username;
            state.mail = data.mail;
            state.isLoggedIn = true;
        },

        UpdateInfo(state, action) {
            const { user, status } = action.payload;
            state.user = user;
            state.status = status;
            state.isLoggedIn = status;
        },

        UpdateInvester(state, action) {
            const { investor_id } = action.payload;
            state.investorId = investor_id;
            state.isVerified = investor_id ? true : false;
        },

        UpdateUserInfo(state, action) {
            const { user } = action.payload;
            state.user = user;
        },

        Logout(state) {
            state.token = "";
            state.mail = "";
            state.username = "";
            state.userId = "";
            state.wallet = "";
            state.isVerified = false;
            state.isLoggedIn = false;
        },
    },
});

export default auth.reducer;

export const { Login, Logout, UpdateInfo, UpdateInvester, UpdateUserInfo } =
    auth.actions;