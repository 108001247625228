import axios from "../helpers/axios"

export const login = async (params) => {
    const { data } = await axios.post("/admin/login", params)
    return data;
}

export const getAllDeposits = async () => {
    const { data } = await axios.get(`/admin/crypto-deposit`)
    return data;
}


export const getAllWithdraws = async () => {
    const { data } = await axios.get(`/admin/crypto-withdraw`)
    return data;
}


export const getBankAllDeposits = async () => {
    const { data } = await axios.get(`/admin/bank-deposit`)
    return data;
}


export const getBankAllWithdraws = async () => {
    const { data } = await axios.get(`/admin/bank-withdraw`)
    return data;
}

export const getAllKYC = async () => {
    const { data } = await axios.get(`/admin/kyc`)
    return data;
}

export const confirmWithdraw = async (withdrawId, hash) => {
    const { data } = await axios.put(`/admin/confirm-crypto-withdrawal?withdrawal_id=${withdrawId}&hashed_string=${hash}`)
    return data;
}


export const confirmDeposit = async (depositId, hash) => {
    const { data } = await axios.put(`/admin/accept-user-crypto-deposit?deposit_id=${depositId}&hashed_string=${hash}`)
    return data;
}

export const confirmKYC = async (userId, hash) => {
    const { data } = await axios.post(`/kyc/approve/${userId}/${hash}`)
    return data;
}


export const rejectKYC = async (userId, hash) => {
    const { data } = await axios.post(`/kyc/reject/${userId}/${hash}`)
    return data;
}


export const increaseBankDeposit = async (hash, params) => {
    const { data } = await axios.post(`/admin/increase-bank-request/${hash}`, params)
    return data;
}

export const confirmBankWithdraw = async (withdrawId, hash) => {
    const { data } = await axios.put(`/admin/confirm-bank-withdrawal?withdrawal_id=${withdrawId}&hashed_string=${hash}`)
    return data;
}


export const confirmBankDeposit = async (depositId, hash) => {
    const { data } = await axios.put(`/admin/accept-user-bank-deposit?deposit_id=${depositId}&hashed_string=${hash}`)
    return data;
}
